import revive_payload_client_TL0yiLscP9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mX0lkMhf9R from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YryDb8bwh1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_16upi5qzbd from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@types+node@22.5.4_sass_72qra23of5nym5wawv52c5ry2a/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_c9s1cCV4Bm from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QE1ub6eRjU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_B3Bp33UNEH from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_sBROa25tWj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_n05jwsp3eJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3EzF1f7BWB from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.6.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_DhAVH826Cm from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._n45rlifg2nltzuwqxjav6mvkiq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_x64FBfAFmO from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.0.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5_jifwpl657i5uqauj4iyd3rlwlq/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import titles_3xh0lWuUJS from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@ty_4nptldr2qstm5oa533z7bju6oa/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_P9lvsqRPR0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@ty_4nptldr2qstm5oa533z7bju6oa/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_FvDjN9BuXh from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@types+node@22._xaurh6aivojd6k4xdsfoqqflpu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_2jZRJ9pzdL from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@types+node@22._xaurh6aivojd6k4xdsfoqqflpu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
export default [
  revive_payload_client_TL0yiLscP9,
  unhead_mX0lkMhf9R,
  router_YryDb8bwh1,
  _0_siteConfig_16upi5qzbd,
  payload_client_c9s1cCV4Bm,
  navigation_repaint_client_QE1ub6eRjU,
  check_outdated_build_client_B3Bp33UNEH,
  view_transitions_client_sBROa25tWj,
  chunk_reload_client_n05jwsp3eJ,
  plugin_vue3_3EzF1f7BWB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DhAVH826Cm,
  unocss_MzCDxu9LMj,
  plugin_x64FBfAFmO,
  titles_3xh0lWuUJS,
  defaults_P9lvsqRPR0,
  siteConfig_FvDjN9BuXh,
  inferSeoMetaPlugin_2jZRJ9pzdL,
  _00_setup_OesUu83x3t
]